import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/hero_illustration.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/screenshots/example_product.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/screenshots/goals_screenshot.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/FindOutMoreButton.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sitewide/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/images/hero_item.png");
