'use client'

import React from 'react'
import posthog from 'posthog-js'

const FindOutMoreButton = ({ buttonLocation }) => {
  const handleClick = () => {
    // Send PostHog event
    posthog.capture('Find Out More Button Clicked', {
      buttonLocation: { buttonLocation },
    })
  }

  return (
    <a
      href="https://calendly.com/zackzornitta/30min"
      target="_blank"
      className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
      onClick={handleClick}
    >
      Find out more
    </a>
  )
}

export default FindOutMoreButton
